import { useTranslation } from "react-i18next";
import "./Payment.scss";
const Payment = ({ totalAmount }: any) => {
  const { t } = useTranslation();
  return (
    <div className="payment-container">
      <div className="payment-card">
        <div className="card-content">
          <div className="amount-details">
            <div className="detail-row">
              <span>{t("PAYMENT.AMOUNT")} :</span>
              <span>
                ${totalAmount?.amount ? totalAmount?.amount.toFixed(2) : " 0"}
              </span>
            </div>
            <div className="detail-row">
              <span>{t("PAYMENT.TAX")} :</span>
              <span>
                ${totalAmount?.taxFee ? totalAmount?.taxFee?.toFixed(2) : " 0"}
              </span>
            </div>
            <div className="detail-row">
              <span>{t("PAYMENT.GATEWAY_FESS")} :</span>
              <span>
                $
                {totalAmount?.stripeFees
                  ? totalAmount?.stripeFees?.toFixed(2)
                  : " 0"}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
