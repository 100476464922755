import { useState } from "react";
import AiStamp from "../../utils/Images/AiStamp";
import CustomModal from "../CustomModal/CustomModal";
import "./AiConfidenceStamp.scss";
import { useTranslation } from "react-i18next";
interface props {
  width?: string;
  height?: string;
  score?: any;
}
const AiConfidenceStamp = ({ width, height, score }: props) => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handlePopupOpen = (e: any) => {
    e.stopPropagation();
    setShow(true);
  };
  const handleClosePopup = () => {
    setShow(false);
  };
  return (
    <>
      <div className="aiStamp" onClick={(e: any) => handlePopupOpen(e)}>
        <AiStamp height={height} width={width} />
        <div className="aiConfidenceMark">
          <span>{score}%</span>
        </div>
      </div>
      <CustomModal
        show={show}
        handleClose={handleClosePopup}
        isHeaderVisible={true}
        isCrossBtn={true}
        isFooterVisible={false}
        instructionHeadTitle={t("GENERAL.EVLUATE_CONFIDENCE")}
        className="closeAssessPopup"
      >
        <div className="markAsCloseModal">
          <div className="closeModalText">
            <span className="">{t("GENERAL.EVLUATE_CONFIDENCE_MSG")}</span>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default AiConfidenceStamp;
