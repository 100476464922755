import { AssessmentButton, CheckboxRadio } from "ui-components";
import "./AddCard.scss";
import { useEffect, useState } from "react";
import Checkout from "../Checkout";
import EditCard from "./EditCard/EditCard";
import { useTranslation } from "react-i18next";

const AddCard = ({
  cardUsed,
  setCardUsed,
  paymentMethod,
  calculatePaymentTax,
  setIsSaveCard,
  isSavaCard,
  rechargeSlabId,
}: any) => {
  const [addNewCard, setAddNewCard] = useState(false);
  const [tempCard, setTempCard] = useState<any>({});
  const [editCard, setEditCard] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (tempCard?.card?.last4) {
      setCardUsed("NEW");
    }
  }, [tempCard]);
  return (
    <div className="addcard">
      {editCard ? (
        <EditCard setEditCard={setEditCard} />
      ) : (
        <>
          {paymentMethod?.length && !addNewCard && (
            <div className="savedCards">
              <CheckboxRadio
                className="mt-3"
                name="ansOptions"
                type="radio"
                checked={cardUsed === "DEFAULT"}
                onChange={() => setCardUsed("DEFAULT")}
              />
              <div
                onClick={() => setEditCard(true)}
                className={`credit-card selectable ${cardUsed === "DEFAULT" ? "activeCard" : "visa"}`}
              >
                <div className="credit-card-last4">
                  <h6>**** **** ****</h6>
                  <h5>{paymentMethod[0]?.last4digit}</h5>
                </div>
                <div className="credit-card-expiry">
                  <span>
                    {paymentMethod[0]?.provider.charAt(0).toUpperCase() +
                      paymentMethod[0]?.provider.slice(1)}
                  </span>
                </div>
              </div>
            </div>
          )}
          {tempCard?.card?.last4 && (
            <div className="savedCards">
              <CheckboxRadio
                className="mt-3"
                name="ansOptions"
                type="radio"
                checked={cardUsed === "NEW"}
                onChange={() => setCardUsed("NEW")}
              />
              <div
                onClick={() => setEditCard(true)}
                className={`credit-card selectable ${cardUsed === "NEW" ? "activeCard" : "visa"}`}
              >
                <div className="credit-card-last4">
                  <h6>**** **** ****</h6>
                  <h5>{tempCard?.card?.last4}</h5>
                </div>
                <div className="credit-card-expiry">
                  <span>
                    {tempCard?.card?.brand.charAt(0).toUpperCase() +
                      tempCard?.card?.brand.slice(1)}
                  </span>
                </div>
              </div>
            </div>
          )}
          {!addNewCard && (
            <div className="addnewcard mt-2">
              <AssessmentButton
                disabled={tempCard?.card?.last4}
                onClick={() => {
                  setAddNewCard(true);
                }}
              >
                + {t("PAYMENT.ADD_NEW_CARD")}
              </AssessmentButton>
            </div>
          )}
          {addNewCard && (
            <Checkout
              rechargeSlabId={rechargeSlabId}
              isSavaCard={isSavaCard}
              setIsSaveCard={setIsSaveCard}
              setTempCard={setTempCard}
              setAddNewCard={setAddNewCard}
              calculatePaymentTax={calculatePaymentTax}
            />
          )}
        </>
      )}
    </div>
  );
};

export default AddCard;
