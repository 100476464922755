import CustomModal from "../../../components/CustomModal/CustomModal";
import RechargeSlab from "./RechargeSlab/RechargeSlab";
import { AssessmentButton, showToast } from "ui-components";
import "./Index.scss";
import AddCard from "./AddCard/AddCard";
import Payment from "./Payment";
import { useEffect, useState } from "react";
import {
  calculatePayment,
  getPaymentMethod,
  rechargeAnAccount,
} from "../../../service/Account/Account";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const PayCheckout = ({ payCheckout, handleClosePayCheckout }: any) => {
  const [rechargeSlabId, setRechargeSlabId] = useState<any>({});
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>({});
  const [paymentMethodID, SetPaymentMethodID] = useState<any>("");
  const [isSavaCard, setIsSaveCard] = useState(false);
  const [cardUsed, setCardUsed] = useState("DEFAULT");
  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  useEffect(() => {
    getPayment();
  }, [rechargeSlabId?.rechargeSlabId]);
  const getPayment = async () => {
    const companyId = localStorage.getItem("companyId") as string;
    const branchId = localStorage.getItem("branchId") as string;
    const paymentMethodData = await getPaymentMethod(companyId, branchId);
    if (
      paymentMethodData[0]?.gatewayPaymentMethodId &&
      rechargeSlabId?.rechargeSlabId
    ) {
      calculatePaymentTax(paymentMethodData[0]?.gatewayPaymentMethodId);
    }
    setPaymentMethod(paymentMethodData);
  };
  // function to calculate total amount with tax and plateform fess
  const calculatePaymentTax = async (paymentId: string) => {
    SetPaymentMethodID(paymentId);
    const paymentCalData = await calculatePayment({
      paymentMethodId: paymentId,
      rechargeSlabId: rechargeSlabId?.rechargeSlabId,
    });
    setTotalAmount(paymentCalData?.data);
  };

  const handlePaymentMethod = async () => {
    const rechargeData = await rechargeAnAccount({
      companyId: localStorage.getItem("companyId"),
      branchId: localStorage.getItem("branchId"),
      rechargeSlabId: rechargeSlabId?.rechargeSlabId,
      paymentMethodId:
        cardUsed === "DEFAULT"
          ? paymentMethod[0]?.gatewayPaymentMethodId
          : paymentMethodID,
      saveCardDetails: isSavaCard,
    });
    if (rechargeData?.status) {
      showToast("Recharge Successful"), "success";
      navigate(`/${currentLanguage}/account/`);
    }
  };
  return (
    <CustomModal
      show={payCheckout}
      isHeaderVisible={true}
      handleClose={handleClosePayCheckout}
      isCrossBtn={true}
      isFooterVisible={false}
      size="xl"
      instructionHeadTitle={t("PAYMENT.PAYMENT_RECHARGE")}
    >
      <div className="payCheckout">
        <div className="row">
          <div className="col-md-6">
            <RechargeSlab
              rechargeSlabId={rechargeSlabId}
              setRechargeSlabId={setRechargeSlabId}
            />
          </div>
          <div className="col-md-6">
            <AddCard
              cardUsed={cardUsed}
              setCardUsed={setCardUsed}
              setIsSaveCard={setIsSaveCard}
              isSavaCard={isSavaCard}
              rechargeSlabId={rechargeSlabId}
              paymentMethod={paymentMethod}
              calculatePaymentTax={calculatePaymentTax}
            />
          </div>
        </div>
        <div className="calculatePaymentConfirm mt-3">
          <div className="w-50">
            <Payment totalAmount={totalAmount} />
          </div>
          <div className="confirmPayment">
            <div className="totalAmountCal">
              <div className="totalAmountText">
                <span>{t("PAYMENT.TOTAL_AMOUNT")} :</span>
                <span>
                  $
                  {totalAmount?.stripeFees
                    ? totalAmount?.stripeFees +
                      totalAmount?.amount +
                      totalAmount?.taxFee
                    : " 0"}
                </span>
              </div>
              <div className="totalCreditText">
                <span>{t("PAYMENT.TOTAL_CREDITS")} :</span>
                <span>
                  $
                  {rechargeSlabId?.credits
                    ? rechargeSlabId?.credits + rechargeSlabId?.extraCredits
                    : " 0"}
                </span>
              </div>
            </div>
            <AssessmentButton
              disabled={!paymentMethodID?.length}
              onClick={() => {
                handlePaymentMethod();
              }}
            >
              {t("PAYMENT.CONFIRM_PAYMENT")}
            </AssessmentButton>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default PayCheckout;
