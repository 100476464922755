import { useEffect, useState } from "react";
import "./RechargeSlab.scss";
import { getRechargeSlab } from "../../../../service/Account/Account";
import RechargeSlabIcon from "../../../../utils/Images/RechargeSlabIcon";
import { useTranslation } from "react-i18next";
const RechargeSlab = ({ rechargeSlabId, setRechargeSlabId }: any) => {
  const [rechargeSlab, SetRechargeSlab] = useState<any>([]);
  const { t } = useTranslation();
  useEffect(() => {
    getSlab();
  }, []);
  const getSlab = async () => {
    const slabData = await getRechargeSlab(
      "9cb9975d-bd7b-4f63-8200-16c023d27d47",
    );
    if (slabData?.length) {
      SetRechargeSlab(slabData);
    }
  };
  return (
    <div className="rechargeSlab">
      {rechargeSlab?.map((item: any, index: number) => {
        return (
          <div
            onClick={() => setRechargeSlabId(item)}
            key={index}
            className={`creditContent brCyan ${rechargeSlabId?.rechargeSlabId === item.rechargeSlabId ? "slabActive" : ""}`}
          >
            <div>
              <RechargeSlabIcon />
              <span className="unBld">$</span>
              <span>{item?.amount}</span>
            </div>
            <div>
              <h6>
                {item?.credits} {t("PAYMENT.CREDITS")}
              </h6>
              <p>
                {t("PAYMENT.EXTRA")} {item?.extraCredits}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RechargeSlab;
