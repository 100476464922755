import React, { useEffect, useState } from "react";
import "./AccountScreen.scss";
import { useNavigate, Routes, Route, useLocation } from "react-router-dom";
import AccountDetails from "./AccountDetails"; // Screen 1
import TransactionHistory from "./TransactionHistory"; // Screen 2
import { useOnlineStatus } from "ui-components";
import LogoTagline from "../../utils/Images/LogoTagline";
import { useTranslation } from "react-i18next";
import BackArrow from "../../utils/Images/BackArrow";
import Footer from "../../components/Footer/Footer";
import { getCompanyAccountInfo } from "../../service/Account/Account";
import i18n from "../../i18nConfig";

const AccountScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("account");
  const isOnline = useOnlineStatus();
  const [accountDetails, setAccountDetails] = useState<any>({});
  const currentLanguage = i18n.language;
  const { t } = useTranslation();
  useEffect(() => {
    handleComapnyAccountDetails();
  }, []);
  useEffect(() => {
    const path = location.pathname;
    if (path.includes("transaction-history")) {
      setActiveTab("transaction-history");
    } else {
      setActiveTab("account");
      navigate("account-details", { replace: true });
    }
  }, [navigate, location.pathname]);

  const handleComapnyAccountDetails = async () => {
    const companyId = localStorage.getItem("companyId") as string;
    const branchId = localStorage.getItem("branchId") as string;
    const accountDetails = await getCompanyAccountInfo(companyId, branchId);
    setAccountDetails(accountDetails);
  };
  const handleTabClick = (tab: React.SetStateAction<string>) => {
    setActiveTab(tab);
    if (tab === "account") {
      navigate("account-details", { replace: true });
    } else if (tab === "transaction-history") {
      navigate("transaction-history", { replace: true });
    }
  };
  return (
    <div className={`account-screen ${!isOnline && "mt-5"}`}>
      <div className="internet-banner">
        <div className={`banner ${!isOnline ? "show zIndexTop" : ""}`}>
          {t("GENERAL.INTERNET_CONNECTION")}
        </div>
      </div>
      <header className="header">
        <div
          className="header-logo"
          onClick={() =>
            navigate(`/${currentLanguage}/assessment/list`, { replace: true })
          }
        >
          <div className="logo">
            <LogoTagline />
          </div>
        </div>
        <div className={`companyNameArrow ${!isOnline && "mt-5"}`}>
          <div onClick={() => window.history.back()}>
            <BackArrow />
          </div>
          <span>Company Name</span>
        </div>
      </header>
      <div className="contentSec">
        <div className="accountsSidebar col-md-2">
          <div
            className={`sidebar-item ${activeTab === "account" ? "active" : ""}`}
            onClick={() => handleTabClick("account")}
          >
            {t("GENERAL.ACCOUNT")}
          </div>
          <div
            className={`sidebar-item ${activeTab === "transaction-history" ? "active" : ""}`}
            onClick={() => handleTabClick("transaction-history")}
          >
            {t("GENERAL.TRANSACTION_HISTORY")}
          </div>
        </div>
        <div className="col-md-10 col-sm-10">
          <Routes>
            <Route
              path="/account-details"
              element={<AccountDetails accountDetails={accountDetails} />}
            />
            <Route
              path="/transaction-history"
              element={<TransactionHistory />}
            />
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default AccountScreen;
