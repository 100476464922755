import "./App.css";
import CreateAssessLayout from "./components/Assessment/Layout/CreateAssessLayout";
import Assessment from "./pages/Assessment";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import AssessmentReview from "./pages/Assessment/sections/AssessmentReview";
import AssessmentSetting from "./pages/Assessment/sections/AssessmentSetting";
import CreateAssessmentResponseList from "./pages/CreatedAssessListAndReview/CreateAssessmentResponseList/CreateAssesmentResponseList";
import CreatedAssessmentList from "./pages/CreatedAssessListAndReview/CreatedAssessmentList/CreatedAssessmentList";
import CreateAssessmentReview from "./pages/CreatedAssessListAndReview/CreateAssessmentReview/CreateAssessmentReview";
import { extractLanguage } from "./utils/Common/Constant";
import Invite from "./pages/Invite/Invite";
import { useEffect } from "react";
import i18n from "./i18nConfig";
import {
  PrivateCompanyRoute,
  PublicCompanyRoute,
} from "./utils/AuthRoutes/AuthRoutes";
import NotFound from "./pages/NotFound/NotFound";
import Unauthorized from "./pages/Unauthorized/Unauthorized";
import AssessmentCustom from "./pages/Assessment/sections/AssessmentCustom";
import AssessmentBuild from "./pages/Assessment/sections/AssessmentBuild";
import Login from "./pages/Assessment/Auth/Login";
import SignupScreen from "./pages/Assessment/Auth/SignupScreen";
import VerifyOtpScreen from "./pages/Assessment/Auth/VerifyOtpScreen";
import CreatePasswordScreen from "./pages/Assessment/Auth/CreatePasswordScreen";
import CreateProfileScreen from "./pages/Assessment/Auth/CreateProfile/CreateProfileScreen";
import AccountScreen from "./pages/Accounts/AccountScreen";
import ResumeScanning from "./pages/ResumeScanning/ResumeScanning";
function App() {
  const { pathname } = useLocation();
  useEffect(() => {
    const language: any = extractLanguage(pathname);
    i18n.changeLanguage(language);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/"
          element={<Navigate to={"/en/company-login"} replace />}
        />
        {/* parent which code will always appear to its child */}
        <Route
          path="/:languageParam/assessment"
          element={
            <PrivateCompanyRoute>
              <CreateAssessLayout />
            </PrivateCompanyRoute>
          }
        >
          <Route
            path="/:languageParam/assessment/template/:tempUniqueId"
            element={<Assessment />}
          />
          <Route
            path="/:languageParam/assessment/build/:tempUniqueId"
            element={<AssessmentBuild />}
          />
          <Route
            path="/:languageParam/assessment/custom/:tempUniqueId"
            element={<AssessmentCustom />}
          />
          <Route
            path="/:languageParam/assessment/settings/:tempUniqueId"
            element={<AssessmentSetting />}
          />
          <Route
            path="/:languageParam/assessment/review/:tempUniqueId"
            element={<AssessmentReview />}
          />
          <Route
            path="/:languageParam/assessment/list"
            element={<CreatedAssessmentList />}
          />
          <Route
            path="/:languageParam/assessment/reviewlist/:assessmentIdParam"
            element={<CreateAssessmentReview />}
          />
          <Route
            path="/:languageParam/assessment/response/:assessmentIdParam"
            element={<CreateAssessmentResponseList />}
          />
          <Route
            path="/:languageParam/assessment/invite/:assessmentIdParam"
            element={<Invite />}
          />
        </Route>
        <Route
          path="/:languageParam/company-login"
          element={
            <PublicCompanyRoute>
              <Login />
            </PublicCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/company-signup"
          element={
            <PublicCompanyRoute>
              <SignupScreen />
            </PublicCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/verify-otp"
          element={
            <PublicCompanyRoute>
              <VerifyOtpScreen />
            </PublicCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/create-password"
          element={
            <PublicCompanyRoute>
              <CreatePasswordScreen />
            </PublicCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/create-profile"
          element={
            <PrivateCompanyRoute>
              <CreateProfileScreen />
            </PrivateCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/account/*"
          element={
            <PrivateCompanyRoute>
              <AccountScreen />
            </PrivateCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/cv-shortlisting"
          element={
            <PrivateCompanyRoute>
              <ResumeScanning />
            </PrivateCompanyRoute>
          }
        />
        <Route
          path="/:languageParam/404-not-found-error"
          element={<NotFound />}
        />
        <Route
          path="/:languageParam/unauthorized-error"
          element={<Unauthorized />}
        />
        <Route path="/:languageParam/*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
