import { Elements } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { loadStripe, Stripe, StripeElements } from "@stripe/stripe-js";
import "./Checkout.scss";
import {
  AssessmentButton,
  AssessmentCheckboxRadio,
  showToast,
} from "ui-components";
import { useTranslation } from "react-i18next";
const stripePromise = loadStripe(`
  ${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);
const Checkout = ({
  setAddNewCard,
  calculatePaymentTax,
  rechargeSlabId,
  setTempCard,
  setIsSaveCard,
  isSavaCard,
}: any) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [elements, setElements] = useState<StripeElements | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    const initializeStripe = async () => {
      const stripeObj = await stripePromise;
      if (!stripeObj) return; // Ensures that stripe is loaded
      const elementsObj = stripeObj.elements();
      const style = {
        base: {
          iconColor: "red",
          color: "#31325F",
          lineHeight: "30px",
          fontWeight: 300,
          fontFamily: "Helvetica Neue",
          fontSize: "16px",
          "::placeholder": {
            color: "#4D4D4D",
          },
        },
      };

      const cardNumberElement = elementsObj.create("cardNumber", {
        style,
        placeholder: "4242 4242 4242 4242",
      });
      const cardExpiryElement = elementsObj.create("cardExpiry", {
        style,
        placeholder: "MM/YY",
      });
      const cardCvcElement = elementsObj.create("cardCvc", {
        style,
        placeholder: "***",
      });

      cardNumberElement.mount("#card-number-element");
      cardExpiryElement.mount("#card-expiry-element");
      cardCvcElement.mount("#card-cvc-element");

      // // Listen for changes on the card number element
      // cardNumberElement.on("change", (event) => {
      //   if (event.brand) {
      //     setCardBrand(event.brand); // Update the card brand state
      //   } else {
      //     setCardBrand(null); // Reset if there's no brand
      //   }
      // });

      setStripe(stripeObj);
      setElements(elementsObj);
    };

    initializeStripe();
  }, []);

  const calculateFinalAmount = async (event: any) => {
    event.preventDefault(); // Correct method to prevent default action
    if (rechargeSlabId?.rechargeSlabId) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js is loaded.
        return;
      }
      // Get the CardElement
      const cardElement = elements.getElement("cardNumber");
      // Create a payment method
      const { paymentMethod }: any = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement as any,
        billing_details: {
          email: "customer@example.com", // Replace with actual email
        },
      });
      if (!paymentMethod?.id?.length) {
        showToast("Please add card details", "error");
        return;
      }
      setTempCard(paymentMethod);
      calculatePaymentTax(paymentMethod?.id);
      setAddNewCard(false);
    } else {
      showToast("Please select slab", "error");
    }
  };
  return (
    <Elements stripe={stripePromise} options={{}}>
      <form className="paymentForm" onSubmit={calculateFinalAmount}>
        <div className="cardDetails">
          <label>{t("PAYMENT.CARD_NUMBER")}</label>
          <div id="card-number-element" className="field"></div>
          <div className="expiryCvc">
            <div className="expiryElement">
              <label className="mb-2">{t("PAYMENT.EXPIRY")}</label>
              <div id="card-expiry-element" className="field "></div>
            </div>
            <div className="cvcElement">
              <label className="mb-2">{t("PAYMENT.CVV")}</label>
              <div id="card-cvc-element" className="field "></div>
            </div>
          </div>
        </div>
        <div className="saveCardDetails">
          <AssessmentCheckboxRadio
            type="checkbox"
            checked={isSavaCard}
            onChange={() => setIsSaveCard(!isSavaCard)}
          />
          <span>{t("PAYMENT.SAVE_CARD")}</span>
        </div>
        <div className="stateWithCountry">
          <div className="country">
            <label className="mb-2">{t("PAYMENT.COUNTRY")}</label>
            <input
              name="country"
              className="countryinputfield"
              placeholder="India"
            />
          </div>
          <div className="country">
            <label className="mb-2">{t("PAYMENT.STATE")}</label>
            <input
              name="state"
              className="countryinputfield"
              placeholder="Madhya Pradesh"
            />
          </div>
        </div>
        <div
          className="country"
          style={{
            marginBottom: "20px",
          }}
        >
          <label className="mb-2">{t("PAYMENT.ADDRESS")}</label>
          <input
            name="address"
            className="countryinputfield"
            placeholder="Address"
          />
        </div>
        <div className="d-flex gap-3 justify-content-end">
          <AssessmentButton
            className="addcard"
            onClick={() => setAddNewCard(false)}
            disabled={!stripe}
          >
            {t("GENERAL.CANCEL")}
          </AssessmentButton>
          <AssessmentButton
            className="addcard"
            onClick={(e) => calculateFinalAmount(e)}
            disabled={!stripe}
          >
            Add Card
          </AssessmentButton>
        </div>
      </form>
    </Elements>
  );
};

export default Checkout;
