const ResumeScanningIcon = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_10982_218)">
        <circle cx="43" cy="43" r="23" fill="white" />
      </g>
      <circle cx="42.8622" cy="42.6208" r="19.2276" fill="white" />
      <circle
        cx="42.8622"
        cy="42.6208"
        r="18.7276"
        stroke="#35D4AE"
        strokeOpacity="0.2"
      />
      <path
        d="M40.0001 33.9999H39.0001C38.0702 33.9999 37.6052 33.9999 37.2237 34.1021C36.1884 34.3795 35.3797 35.1881 35.1023 36.2234C35.0001 36.6049 35.0001 37.0699 35.0001 37.9999M46.0001 33.9999H47.0001C47.9301 33.9999 48.3951 33.9999 48.7766 34.1021C49.8118 34.3795 50.6205 35.1881 50.8979 36.2234C51.0001 36.6049 51.0001 37.0699 51.0001 37.9999M51.0001 47.4999V48.4999C51.0001 49.9999 50.5982 50.4999 50.5982 50.4999C50.5982 50.4999 49.9562 51.3347 49.5001 51.598C48.8039 51.9999 47.8693 51.9999 46.0001 51.9999M35.0001 47.4999V48.4999C35.0001 49.9999 35.402 50.4999 35.402 50.4999C35.402 50.4999 36.0441 51.3347 36.5001 51.598C37.1963 51.9999 38.1309 51.9999 40.0001 51.9999M34.0001 44.9999H52.0001M38.0001 40.9999V44.9999H48.0001V40.9999C48.0001 40.0571 48.0001 39.5857 47.7072 39.2928C47.4143 38.9999 46.9429 38.9999 46.0001 38.9999H40.0001C39.0573 38.9999 38.5859 38.9999 38.293 39.2928C38.0001 39.5857 38.0001 40.0571 38.0001 40.9999Z"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_10982_218"
          x="0"
          y="0"
          width="86"
          height="86"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_10982_218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_10982_218"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ResumeScanningIcon;
