import { useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { AssessmentButton } from "ui-components";
import PayCheckout from "./Checkout";
import { convertDate } from "../../utils/Common/Constant";

const AccountDetails = ({ accountDetails }: any) => {
  const { t } = useTranslation();
  // Get current language
  // const currentLanguage = i18n.language;
  // const navigate = useNavigate();
  const [payCheckout, SetPayCheckout] = useState(() => {
    const isPopUpOpen = new URLSearchParams(location.search).get("rechrSlab");
    return isPopUpOpen ? JSON.parse(isPopUpOpen) : false;
  });
  const handlePayCheckout = () => {
    SetPayCheckout(true);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("rechrSlab", "true");
    history.replaceState({}, "", url.toString());
  };
  const handleClosePayCheckout = () => {
    SetPayCheckout(false);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("rechrSlab", "false");
    history.replaceState({}, "", url.toString());
  };
  return (
    <div className="accountsRightDescription">
      <div className="topRow">
        <div className="lastRechargeSec ">
          <div className="textRow">
            <span className="rightContentHeading ">
              {t("GENERAL.LAST_RECHARGE")}
            </span>
            <span className="textInfo">
              {convertDate(accountDetails?.lastRechargeDate, false, true)}
            </span>
          </div>
          <AssessmentButton
            onClick={() => handlePayCheckout()}
            className="rechargeBtn"
          >
            {t("GENERAL.RECHARGE")}
          </AssessmentButton>
        </div>
        <div className="textRow alignEnd">
          <span className="rightContentHeading">
            {t("GENERAL.ACCOUNT_BALANCE")}
          </span>
          <span className="textInfo bolderBalance">
            {accountDetails?.accountBalance}
          </span>
        </div>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <div className="d-flex gap-5">
          <div className="d-flex flex-column">
            <span className="rightContentHeading">
              {t("ACCOUNT.LAST_RECHARGE_AMOUNT_CREDITDS")}
            </span>
            <span className="textInfo">
              ${accountDetails?.lastRechargeAmount} ({accountDetails?.credits})
            </span>
          </div>
        </div>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <span className="rightContentHeading">
          {t("GENERAL.TOTAL_ASSESSMENTS")}
        </span>
        <span className="textInfo">{accountDetails?.totalAssessments}</span>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <span className="rightContentHeading">
          {t("ACCOUNT.LAST_ASSESSMENT_CREATED")}
        </span>
        <span className="textInfo">
          {convertDate(accountDetails?.lastAssessmentCreated, false, true)}
        </span>
      </div>
      {payCheckout && (
        <PayCheckout
          payCheckout={payCheckout}
          handleClosePayCheckout={handleClosePayCheckout}
        />
      )}
    </div>
  );
};

export default AccountDetails;
