import BackArrow from "../../../../../utils/Images/BackArrow";
import "./EditCard.scss";
const EditCard = ({ setEditCard }: any) => {
  return (
    <div>
      <div className="backBtn" onClick={() => setEditCard(false)}>
        <BackArrow />
      </div>
      <div className="editcard">
        <p>Edit card details</p>
      </div>
    </div>
  );
};

export default EditCard;
